import React from 'react';

import { VisualCodeMentor } from '@wecode-codingbootcamp/frontend-commons';

const VideoLecture = React.memo(({
  content, 
  height, 
  data,
  activeTab,
  setActiveTab,
}: {
  content     : string,
  height      : string,
  data        : Record<string, any>,
  activeTab   : string,
  setActiveTab: (tab: string) => void
}) => {
  // Parse the content to get the 
  // properties of the video lecture
  // such as mobileUrl, url and orientation
  const parser  = new DOMParser();
  const doc     = parser.parseFromString(content, 'text/html');
  const element = doc.body.firstChild as Element;
  
  const mobileUrl   = element?.getAttribute('mobileurl');
  const url         = element?.getAttribute('url');
  const orientation = element?.getAttribute('orientation');

  return <VisualCodeMentor 
    url          = {url!}
    mobileUrl    = {mobileUrl!}
    orientation  = {orientation! as 'portrait' | 'landscape'}
    data         = {data}
    height       = {height}
    activeTab    = {activeTab}
    setActiveTab = {setActiveTab}
  />
});

export default VideoLecture;
