import React    from 'react';
import { Link } from 'react-router-dom';

import Tag      from 'components/core/Tag';
import { PATH } from 'constant';

export type ClassCardProps = {
  sequence      : number;
  courseId?     : number;
  title         : string;
  imageSrc      : string;
  price         : string;
  chaptersCount : number;
  description   : string;
  tags          : string[];
};

const ClassCard = ({ 
  sequence,
  courseId,
  title,
  imageSrc,
  description,
  price,
  chaptersCount,
  tags 
}: ClassCardProps) => {

  const SequenceTag = () => {
    const color = courseId ? "bg-blue-500" : "bg-gray-500";

    return (
      <div className={`absolute top-0 left-0 m-2 ${color} text-white text-xs font-bold border border-white px-2 py-1 rounded-full`}>
        {sequence}
      </div>
    );
  }

  const Image = () => {
    return (
      <img className="w-full h-24 sm:h-36 object-fill" src={imageSrc} alt={title} />
    );
  };

  const Title = () => {
    return (
      <div className="line-clamp-2 h-8 sm:h-10 font-medium sm:font-medium text-xs sm:text-sm mb-1 sm:mb-2">{title}</div>
    );
  };

  const Description = () => {
    return (
      <div className="hidden sm:block ">
        <p className="line-clamp-2 h-8 text-gray-400 text-xs mb-1 sm:mb-2">{description}</p>
      </div>
    );
  };

  const Price = () => {
    return (
      <div className="flex justify-between items-center pr-1">
        <p className="font-bold text-xs sm:text-sm text-blue-500 mb-1">{price}</p>
        <p className="text-xs sm:text-sm text-blue-500 mb-1">총 {chaptersCount}강</p>
      </div>
    );
  }

  const Tags = () => {
    return (
      <div className="h-6 sm:h-9 truncate sm:pt-2">
        {tags.map((tag, index) => <Tag key={index} {...{tag}} />)}
      </div>
    );
  };

  return (
    <Link to={courseId ? PATH.COURSE.toDescription(courseId) : ""}>
      <div className="relative full-w rounded-md overflow-hidden shadow-lg m-1 h-auto">
          <Image       /> 
          <SequenceTag /> 
          <div className="px-2 py-2 sm:px-2 sm:py-4">
            <Title       /> 
            <Description /> 
            <Price       /> 
            <Tags        /> 
          </div>
        </div>
    </Link>
  );
};

export default ClassCard;

