import React        from 'react';
import { Link     } from 'react-router-dom';
import { Checkbox } from '@wecode-codingbootcamp/frontend-commons';

import { PATH } from 'constant';

type Props = {
  userAgreementChecked    : boolean;
  setUserAgreementChecked : (value: boolean) => void;
  onClose                 : ()               => void;
};

const UserAgreementCheckBox = ({
  onClose ,
  userAgreementChecked,
  setUserAgreementChecked,
}: Props) => {
  const UserAgreement = () => {
    return (
      <Link to={PATH.TERM.agreement} onClick={onClose} className="underline">
        이용안내 
      </Link>
    );
  };

  const RefundPolicy = () => {
    return (
      <Link to={PATH.TERM.privacy} onClick={onClose} className="underline">
        환불 
      </Link>
    );
  };

  const handleClick = () => {
    setUserAgreementChecked(!userAgreementChecked);
  };

  return (
    <div className="flex items-center pt-10 pl-1 md:pl-0 md:mx-auto w-fit">
      <Checkbox 
        id        = "user-agreement"
        value     = "agreed"
        checked   = {userAgreementChecked}
        onChange  = {handleClick}
      />
      <label 
        htmlFor   = "user-agreement"
        className = "ms-2 text-[0.67rem] sm:text-xs md:text-sm font-semibold text-slate-700">
        상품, 가격, <RefundPolicy /> 및 <UserAgreement />등을 확인하였으며 구매에 동의합니다.
      </label>
    </div>
  );
};

export default UserAgreementCheckBox;
