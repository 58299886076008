import React    from 'react';
import { Link } from 'react-router-dom';

import WecodeLogo    from 'components/core/Icon/Logo/Wecode';
import WecondSymbol  from 'assets/wecode_symbol_b@2x.png';
import hamburgerMenu from 'assets/common/ic_hamburger.png';
import Profile       from 'components/core/GNB/Profile';
import { PATH      } from 'constant';
import { 
  useModal, 
  ModalTypes  
} from 'contexts/ModalContext';

type Props = {
  className?  : string;
  activeTab   : string;
  setActiveTab: (tab: string) => void;
}

const NavBar = ({className, activeTab, setActiveTab}: Props) => {
  const modal = useModal();

  const Nav = ({children}: {children: React.ReactNode}) => {
    return (
      <nav className = {`
        flex justify-between items-center 
        mx-auto py-3
      `}>
        {children}
      </nav>
    );
  };

  const Logo = () => {
    return (
      <Link to={PATH.HOME.base}>
        <div className="hidden lg:block mr-10">
          <WecodeLogo />
        </div>
        <div className="lg:hidden relative w-8 h-8 cursor-pointer">
            <img src= {WecondSymbol} className="rounded-full object-cover" alt="Wecode" />
        </div>
      </Link>
    );
  }

  const Tabs = () => {
    const tabs = ['Video', 'Code', 'Text'];

    return (
      <div className="lg:hidden flex items-center bg-gray-800 rounded-full p-1 mx-auto">
        {tabs.map(tab => (
          <button
            key       = {tab}
            className = {`
              ${ activeTab === tab ? 'bg-white text-black font-bold' : 'text-white' } 
              px-4 py-2 
              text-xs
              rounded-full focus:outline-none
            `}
            onClick   = {() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    );
  };

  const HamburgerMenu = () => {
    const GNB_ITEMS = [
      { id : 1, path : PATH.HOME.base, content       : '홈'                          } ,
      { id : 3, path : PATH.MYPAGE.myCourse, content : '내 강의', shouldLogin : true } ,
    ];

    const openHamburgerMenu = () => {
      modal?.openModal(ModalTypes.HAMBURGER_MENU, {items: GNB_ITEMS});
    };

    return (
      <div className="lg:hidden cursor-pointer" onClick={openHamburgerMenu}>
        <img src={hamburgerMenu} alt="menu button" className="w-7 h-7"/>
      </div>
    );
  }

  return (
    <header className = {`w-full shadow-lg border-b border-gray-400 bg-white px-2 md:px-4 ${className ?? ''}`}>
      <Nav>
        <Logo />
        <Tabs />
        <div className = "hidden lg:flex items-center">
          <Profile />
        </div>
        <HamburgerMenu />
      </Nav>
    </header>
  );
};

export default NavBar;
