import React      from 'react';
import { Button } from '@wecode-codingbootcamp/frontend-commons';

import Icon      from 'components/core/Icon/Icon';
import BaseModal from 'components/modal/BaseModal/BaseModal';
import css       from './RefundModal.module.scss';

interface Props {
  onClose: () => void;
}

const RefundModal = ({ onClose }: Props) => {
  return (
    <BaseModal switchModal={onClose}>
      <div className={css.modal}>
        <div className={css.modalHeader}>
          <p className={css.title}>환불 안내</p>
          <Icon name="close" onClick={onClose} />
        </div>
        <div className={css.modalBody}>
          <div className={css.subTitle}>
            환불하신 강의는
            <br />
            다시 보실 수 없습니다.
          </div>
          <p className={css.subText}>
            강의를 진행한 내역, 과제, 질문 내역도 환불과 함께 모두 사라집니다.
          </p>
        </div>
        <div className={css.modalFooter}>
          <div className={css.info}>
            {REFUND_DATA.map(refund => (
              <>
                <p className={css.infoTitle}>{refund.title}</p>
                <p className={css.infoContent}>{refund.content}</p>
              </>
            ))}
          </div>
          <div className={css.buttonGroup}>
            <Button variant="lineGrey" size="full" onClick={onClose}>
              닫기
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default RefundModal;

const REFUND_DATA = [
  {
    id      : 0,
    title   : '환불이 가능한 코스',
    content : '열람한 강의 자료가 2개 이하이면서, 구매일에서 7일이 지나지 않은 코스만 환불을 진행하실 수 있습니다.',
  },
  {
    id      : 1,
    title   : '환불까지 걸리는 시간',
    content : '환불이 정상 접수된 날짜를 기준으로 3일~7일(영업일 기준) 이내에 결제가 취소됩니다. 여러 강의를 취소하신 경우, 취소된 강의 모두 일괄 환불 처리됩니다.',
  },
  {
    id      : 2,
    title   : '환불은 채널톡으로 진행됩니다.',
    content : '환불하실 강의명과 함께 채널톡으로 문의해주세요.',
  },
];
