export const codingBootcampClasses = [
  {
    title: '🚩 HTML/CSS에서 JS까지! 웹개발 한 번에 끝내기',
    description: '믿을 수 있는 위코드의 커리큘럼! HTML/CSS부터 JS까지 순서대로 공부하며 웹개발의 모든 것을 체계적으로 배워보세요!',
    items: [
      {
        sequence: 1,
        courseId: 4,
        title: 'CSS Display 속성 처음부터 끝까지 완전 정복',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course4-main-thumbnail-small.png',
        description: '웹사이트 레이아웃, 혼자 만들다가 길을 잃진 않으셨나요? Display 속성 입문자를 위한 강의예요! 함께 만들어봐요',
        price: '₩ 3,000',
        chaptersCount: 7,
        tags: ['Display', 'Block', 'Inline', 'Inline-block'],
      },
      {
        sequence: 2,
        courseId: 7,
        title: '오늘부터 바로 써먹는 CSS Flex 필수 가이드',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course7-main-thumbnail-small.png',
        description: 'Flex로 웹사이트를 만드는 진짜 방법을 배워보세요. 듣자마자 오늘부터 바로 써먹을 수 있는 Flex를 알려드려요!',
        price: '₩ 3,000',
        chaptersCount: 7,
        tags: ['Flex', 'justify-content', 'align-items'],
      },
      {
        sequence: 3,
        courseId: 11,
        title: '실전 JS - 브라우저 이벤트와 핸들러 함수 완전 정복',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/11/assets/course_11_description_image_3.png',
        description: 'JavaScript를 사용해서 브라우저 이벤트와 핸들러 함수를 실제로 사용하는 법을 배웁니다!',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['브라우저 이벤트', '핸들러 함수', 'JS'],
      },
      {
        sequence: 4,
        courseId: 9,
        title: '실전 응용을 통해 배우는 JS배열과 반복문',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/9/assets/course9_description_image_2.png',
        description: 'JavaScript 배열과 반복문을 다양한 방법으로 활용하여, 웹개발에서 가장 자주 사용되는 형태를 개발해보아요!',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['JavaScript', '배열', '반복문'],
      },
      {
        sequence: 5,
        courseId: 10,
        title: '실전 활용에 초점을 맞춘 자바스크립트 함수와 객체',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/10/assets/course_10_description_image_2.png',
        description: `단순 이론에서 벗어나 JS 함수와 객체를 실제 웹개발에서 사용하는 법을 배웁니다!`,
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['함수', '객체', 'JavaScript']
      },
      {
        sequence: 6,
        courseId: 12,
        title: '브라우저 저장소, 응용과 활용으로 완벽 마스터',
        imageSrc: 'https://cdn1.wecode.co.kr/wecode/courses/12/assets/Course12-main-thumbnail-small.png',
        description: '로컬스토리지, 세션스토리지, 쿠키까지 세가지 브라우저 저장소의 활용법을 배웁니다. 실제 사용되는 예시들로 쉽게 이해해보세요!',
        price: '₩ 3,000',
        chaptersCount: 6,
        tags: ['쿠키', '로컬 스토리지', '세션 스토리지'],
      },
      {
        sequence: 7,
        title: '프론트엔드 레벨업! 상태 관리와 함께하는 주문 시스템 개발',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course7-main-thumbnail-small-comingsoon.png',
        description: '웹서비스의 반응 속도를 높이고, 오류 발생을 줄여 더 나은 사용자 경험을 제공해요! 프론트엔드 레벨업! ',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['React', '상태관리'],
      },
      {
        sequence: 8,
        title: '백엔드 레벨업! 트랜잭션 처리와 쿼리 최적화를 통한 사용성 개선',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course8-main-thumbnail-small-comingsoon.png',
        description: '글로벌 탑티어 회사들부터 개인 서비스에 이르기까지 모든 서비스들이 고민하는 백엔드 최적화. 지금부터 성장하세요!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['Transaction', 'Query', 'RDB'],
      },
      {
        sequence: 9,
        title: '클라우드 호스팅과 함께하는 첫 서비스 배포',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course9-main-thumbnail-small-comingsoon.png',
        description: '아마존(Amazon)에서 제공하는 클라우드 서비스에 대해 학습하며, 웹서비스를 실제로 배포하고 사용해보아요!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['AWS', 'Cloud', '배포'],
      },
      {
        sequence: 10,
        title: '내 손으로 직접하는 서비스 배포, 그리고 자동화 구축까지',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course10-main-thumbnail-small-comingsoon.png',
        description: '실무에 착! 배포 전과정 실습부터, 완전한 CI/CD 파이프라인으로 자동화 구축까지!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['배포', '자동화', 'CI/CD'],
      },
      {
        sequence: 11,
        title: '마이크로 서비스 아키텍쳐란 무엇일까요?',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course11-main-thumbnail-small-comingsoon.png',
        description: '더이상 MSA 무서워하지 마세요! 서비스별로 필요한 마이크로 서비스 아키텍처에 대해 배워봅니다.',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['MSA', 'Project'],
      },
      {
        sequence: 12,
        title: '한걸음씩 시작하는 모니터링',
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course12-main-thumbnail-small-comingsoon.png',
        description: '서비스의 균형과 안정, 보안을 위해 놓칠 수 없는 모니터링. 상세한 설명과 부하 테스트 실습과 함께해요!',
        price: '₩ 7,500',
        chaptersCount: 10,
        tags: ['모니터링', '안정성'],
      },
    ]
  },
];

export const classPromotions = [
  {
    title: "⏳ 곧 열려요! 핵심 내용에 집중한 개별 강의",
    description: '내게 맞는 강의를 찾고 계시나요? 더 깊은 지식을 배우고 싶으신가요? Wecode가 여러분을 위해 딱 맞춰 준비한 강의를 곧 만나보세요!',
    items: [
      {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course13-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      },
      {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course14-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course15-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course16-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course17-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course18-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course19-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course20-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }, {
        imageSrc: 'https://s3.ap-northeast-2.amazonaws.com/cdn.wecode.co.kr/wecode/course_asset/Course21-main-thumbnail-comingsoon-simple.png',
        price: '₩ 3,000',
        title: 'Wecode',
      }
    ]
  }
];
