import React               from 'react'
import { MarkdownContent } from '@wecode-codingbootcamp/frontend-commons';

const Privacy = () => <MarkdownContent className="mt-10" content={MD.trim()} />

export default Privacy;

const MD = `
# 개인정보 처리 방침

주식회사 그레이스풀레인(이하 "회사")는 개인정보보호법, 통신비밀 보호법, 전기통신사업법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 등을 준수하고 있습니다.<br></br>

회사는 본 개인정보 처리 방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드리고자 합니다. 개인정보 처리 방침이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다.<br></br>

본 개인정보처리 방침은 회사가 제공하는 wecode(이하 '서비스')에 적용됩니다.<br></br>

본 개인정보처리 방침은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있으며, 개정된 사항은 개인정보 처리 방침을 시행하기 최소 7일 전에 쉽게 알아볼 수 있도록 회사가 제공하는 서비스 페이지에 공지하도록 하겠습니다.<br></br>

회사의 개인정보 처리 방침은 다음과 같은 내용을 포함하고 있습니다.<br>

1 개인정보의 수집 및 이용 목적, 수집하는 개인정보의 항목<br>
2 개인정보의 위탁 및 제3자 제공<br>
3 개인정보 보유 및 이용 기간<br>
4 이용자의 권리와 행사 방법<br>
5 개인정보의 파기에 관한 사항<br>
6 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br>
7 개인정보 보호를 위한 기술적·관리적 대책<br>
8 개인정보 보호 책임자의 성명, 연락처, 부서<br>
9 고지의 의무<br>

<br>

## 1. 개인정보의 수집 및 이용 목적, 수집하는 개인정보의 항목
<br>

(1) 회사는 이용자로부터 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.<br></br>
(2) 서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.<br></br>

1 [최초 서비스 가입 시: (필수) 이메일, 전화번호, 프로필 사진, 닉네임  (선택) 성별, 출생 연도, 생일, 이름]<br></br>
2 [서비스 결제 시: 결제 방법(간편결제, 카드), 기타 결제 관련 정보(가격, 결제 고유 번호)]<br></br>
3 [환불 처리 시: 계좌은행, 계좌번호, 예금주 명 등]<br></br>

(3) 서비스 이용 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
<br></br>

1 [이용자 입력 내용 및 상태 정보, 서비스 접속일, 접속 IP 정보, 쿠키, MAC 주소 등 서비스 이용 기록, 불량 이용 기록, 서비스 사용시간, 기기 정보, 접속 로그, 이용정지 기록 등]
<br></br>
(4) 회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br></br>

1 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우<br></br>
2 온, 오프라인에서 진행되는 이벤트, 세미나 등에서 수집되는 경우<br></br>
3 회사와 제휴한 외부 기업 등으로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 정보통신망법에 따라 제휴사에서 이용자에게 개인정보 제3자 제공 동의 등을 받은 후에 회사에 제공합니다.<br></br>
4 고객센터를 통한 상담 과정에서 메일, 팩스 전화 등으로 수집하는 경우<br></br>
5 기기 정보와 같은 생성 정보는 서비스 이용 과정에서 생성되어 수집될 수 있습니다.<br></br>

(5) 회사는 법정 대리인의 동의가 필요한 만 14세 미만 아동에게는 서비스를 제공하지 않으며, 그에 따라 만 14세 미만 아동의 개인정보는 수집하고 있지 않습니다.<br></br>
(6) 회사는 수집한 개인정보를 아래의 목적으로만 이용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.<br></br>

1 서비스 제공, 서비스 방문 및 이용 기록의 분석, 서비스 이용에 대한 통계, 맞춤화된 서비스 제공 및 광고 게재 등 신규 서비스 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.<br></br>
2 이벤트 참여, 유료 서비스 제공에 따르는 본인 인증, 구매 및 요금 결제, 상품 및 서비스의 배송을 위하여 개인정보를 이용합니다.<br></br>
3 이벤트 정보 및 참여 기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.<br></br>
4 법령 및 이용약관을 위반하는 이용자에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 약관 개정 등의 고지사항 전달, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.<br></br>
5 회사는 보다 나은 서비스의 제공을 위하여 아래와 같은 빅데이터 분석 및 활용을 위한 목적으로 이용내역 정보(서비스 이용 기록, 인터넷 접속 정보 파일, 접속 기기 정보 등)와 개인정보를 이용합니다.
<br></br>

  가. 회사는 서비스를 제공하기 위하여 개인 식별성이 없는 결제 정보 등을 수집합니다. 이와 같은 정보는 향후 고객의 서비스 개선을 위하여 기존에 수집된 개인 정보와 결합되어 분석, 활용될 수 있습니다.
      <br></br>
  나. 회사는 고객의 공개된 정보 및 서비스 이용 기록(로그)과 메타데이터와 같은 정보를 수집하여 이용자에게 보다 큰 효용을 제공할 수 있도록 분석 및 활용을 하고 있습니다. 회사는 분석된 정보를 통해 고객별 관심에 맞춘 서비스를 우선적으로 제공하며, 고객의 관심 분야에 맞는 광고를 제공하거나 이벤트 참여 기회를 제공하는 등 마케팅 목적으로 활용합니다. 회사는 이용자의 개인정보를 수집할 경우 반드시 이용자의 동의를 얻어 수집하며, 이용자의 기본적 인권을 침해할 우려가 있는 사상, 정치적 성향, 범죄기록, 건강 상태 등의 정보는 이용자의 동의 또는 법령의 규정에 의한 경우 이외에는 수집하지 않습니다.<br></br>

(7) 회사는 이용자의 개인정보를 수집할 경우 반드시 이용자의 동의를 얻어 수집하며, 이용자의 기본적 인권을 침해할 우려가 있는 사상, 정치적 성향, 범죄기록, 건강 상태 등의 정보는 이용자의 동의 또는 법령의 규정에 의한 경우 이외에는 수집하지 않습니다.<br></br>

<br>

## 2. 개인정보의 위탁 및 제3자 제공

1. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.

<table style="border: 1px solid black;">
  <tbody>
    <tr style="border: 1px solid black;">
      <th  style="border: 1px solid black; background-color:lightgray;">순번</th>
      <th  style="border: 1px solid black; background-color:lightgray;">위탁사무명</th>
      <th  style="border: 1px solid black; background-color:lightgray;">수탁자</th>
      <th  style="border: 1px solid black; background-color:lightgray;">위탁하는 업무의 내용</th>
    </tr>
    <tr>
      <td  style="border: 1px solid black;">1</td>
      <td  style="border: 1px solid black;">물리적 데이터 저장 및 가공</td>
      <td  style="border: 1px solid black;">Amazon Web Services, Inc.</td>
      <td  style="border: 1px solid black;">시스템 인프라 클라우드를 토안 물리적 운영환경 위탁 <br></br> 이전 항목: ID/PW, 웹로그, 쿠키/세션, 성명, 이메일, 전화번호 등</td>
    </tr>
    <tr>
      <td  style="border: 1px solid black;">2</td>
      <td  style="border: 1px solid black;">전자지급결제대행서비스</td>
      <td  style="border: 1px solid black;">[토스페이먼츠]</td>
      <td  style="border: 1px solid black;">서비스 과금 결제 및 정산 시 전자적 지급 결제 대행</td>
    </tr>
  </tbody>
</table>

(2) 회사는 위탁계약 체결 시 「개인정보 보호법」제26조에 따라 위탁업무 수행 목적 외 개인정보 처리 금지, 기술적·관리적 보호 조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br></br>
(3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리 방침을 통하여 공개하도록 하겠습니다.<br></br>
(4) 회사는 정보주체의 개인정보를 제1조에서 명시한 범위 내에서만 처리하며, 이용자의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.<br></br>
(5) 회사는 타 법령에 특별한 규정이 있는 경우를 제외하고, 개인정보를 고지 또는 명시한 범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다.<br></br>

<br>

## 3. 개인정보 보유 및 이용 기간
<br>

(1) 회사는 수집한 정보를 수집 및 이용 목적이 달성된 때까지(개인정보의 수집 시 별도의 동의를 받은 때에는 별도의 동의를 받은 기간까지) 보유 및 이용할 수 있습니다. 원칙적으로 보유 및 이용 기간이 경과한 개인정보는 회사의 하드디스크에서 완전히 삭제되며, 문서의 경우 분쇄기로 분쇄하여 어떠한 용도로도 이용될 수 없도록 처리됩니다.<br></br>
(2) 전 항에도 불구하고, 회사는 이용자에게 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 보유 및 이용될 수 있습니다.<br></br>
(3) 이용자에게 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우는 아래와 같습니다.<br></br>

1 부정 이용 방지: 보유 및 이용 기간 경과 일로부터 1년<br></br>

(‘부정 이용 방지’란, i. 서비스와 관련한 약관, 계약, 운영정책 등을 위반한 이용자의 서비스 재사용을 금지시키기 위한 경우, ii. 부적절한 행위를 한 전력이 있는 이용자의 서비스 재사용을 금지시키기 위한 경우, iii. 원활한 서비스 제공이 어려운 이용자의 서비스 사용을 금지시키기 위한 경우, iv. 법령, 선량한 풍속, 기타 사회통념상 허용되지 않는 행위를 한 이용자에 대한 서비스 사용을 금지시키기 위한 경우 등을 의미합니다)<br></br>

2 고객 CS 업무 필요시: 보유 및 이용 기간 경과 일로부터 6개월<br></br>

(4) 관계 법령 등에 따라 일정 기간 정보의 보관을 규정하는 경우는 아래와 같습니다.<br></br>

1 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지<br></br>
2 서비스 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지<br></br>
3 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 보관 기간 

  - (1) 표시·광고에 관한 기록: 6개월
  - (2) 소비자 불만 또는 분쟁처리, 신용 정보의 수집·처리 및 이용 등에 관한 기록: 3년
  - (3) 계약 또는 청약철회, 대금 결제, 재화 등의 공급 기록: 5년
  - (4) 컴퓨터 통신 또는 인터넷 로그기록 자료, 접속지역 추적 자료: 3개월

4 정보통신망 이용 촉진 및 정보보호 등에 관한 법률과 통신비밀 보호법에 따른 보관 기간

  (1) 본인 확인에 관한 기록: 6개월
  (2) 사이트 방문에 관한 기록: 3개월

(5) 회사는 법령 및 ‘개인정보 유효 기간제’에 따라 1년간 서비스를 이용하지 않는 장기 서비스 미 이용자의 개인정보를 사전 통지 후 분리 보관하고 5년 후 파기하고 있습니다.<br></br>

<br>

## 4. 이용자의 권리와 행사 방법
<br>

(1) 이용자는 언제든지 다음의 사항에 관하여 열람, 수정을 요구할 수 있습니다.<br></br>

1 회사가 보유하고 있는 이용자의 개인정보<br></br>
2 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 내역<br></br>
3 회사에 개인정보 수집·이용·제공 등에 대해 동의한 내역<br></br>

(2) 이용자는 회사 서비스에서 직접 자신의 정보를 열람, 정정을 할 수 있으며, 별도로 개인정보 보호 책임자에게 서면, 전화, 이메일 등을 통하여 개인정보의 열람, 정정을 요청할 수 있습니다.<br></br>

(3) 이용자는 언제든지 자신의 개인정보처리의 정지를 요구할 수 있습니다.<br></br>
(4) 이용자는 언제든지 개인정보의 수집, 이용, 제공 등에 대한 동의 의사표시를 철회할 수 있습니다.<br></br>
(5) 이용자가 회사 서비스에서 본인 확인 절차를 거친 후 직접 동의 철회를 하거나, 별도로 개인정보 보호 책임자에게 서면, 전화 또는 이메일 등을 통하여 연락하면 지체 없이 이용자의 개인정보를 파기하는 등 필요한 조치를 취합니다. 단, 동의 철회가 있더라도 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우, 또는 관계 법령에 따라 최소한의 정보가 보관됩니다.<br></br>
(6) 본 조에 따른 권리 행사는 정보주체의 위임을 받은 자 등 대리인을 통하여 할 수 있습니다. 이 경우 위임장을 제출하여야 합니다.<br></br>

<br>

## 5. 개인정보의 파기에 관한 사항
<br>

이용자의 개인정보는 원칙적으로 개인정보 처리 목적이 달성되면 지체 없이 파기됩니다. 다만, 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우, 또는 관계 법령에 의해 보관하여야 하는 정보는 법령이 정한 기간 동안 별도 분리보관 후 파기됩니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br></br>

1 파기 절차

<br>

이용자가 입력한 정보는 목적 달성 후 지체 없이 파기되고, 관련 법령에 따라 보관되어야 할 경우 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령을 준수하여 일정 기간 동안 안전하게 보관된 후 지체 없이 파기됩니다. 이때, DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 이용되지 않습니다.<br></br>

2 파기 방법
<br>

  - (1)  전자적 파일 형태의 정보는 복구 및 재생할 수 없는 기술적 방법을 사용하여 완전하게 삭제합니다.
  - (2)  종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
<br>

3 파기 기한


이용자의 개인정보는 개인정보의 보유기간이 경과된 경우 또는 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 그러한 사유가 발생한 것으로 인정되는 날 즉시 그 개인정보를 파기합니다.<br></br>

<br>

## 6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
<br>

(1) 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br></br>
(2) 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다. 서비스 이용과정이나 사업 처리 과정에서 쿠키 정보, IP 주소, 서비스 이용 기록, 홈페이지 방문 기록, 기기 정보(하드웨어 모델, 운영체제, 고유 기기 식별 번호 등과 같은 서비스 이용 기록)이 생성되어 홈페이지 사용 만족도 향상을 위하여 수집될 수 있습니다. 이와 같이 수집된 정보는 개인 정보와의 연계 여부에 따라 개인 정보에 해당하거나 해당하지 아니할 수 있습니다.<br></br>

1 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br></br>
2 쿠키의 설치·운영 및 거부: 웹 브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.<br></br>
3 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br></br>

(3) 이용자는 쿠키 정보 등의 생성과 수집에 대한 선택권을 가지고 있습니다. 이용자는 홈페이지에 접속하는 환경을 직접 설정함으로써 모든 쿠키 정보 등의 생성 및 수집을 허용하거나 거부할 수 있고, 생성 및 수집 때마다 별도의 확인을 거치도록 할 수 있습니다. 단, 쿠키 정보 등에 대한 생성 및 수집을 거부하는 경우에는 홈페이지의 일부 이용이 제한될 수 있습니다.<br></br>

<br>

## 7. 개인정보 보호를 위한 기술적/관리적 대책
<br>

회사의 개인정보 보호를 위한 기술적/관리적 대책은 다음과 같습니다.

<br>

1 개인정보의 안전한 처리를 위한 내부관리 계획의 수립·시행<br></br>
2 수집한 개인 정보에 대한 암호화 장치<br></br>
3 컴퓨터 바이러스에 의한 개인정보의 침해를 막기 위한 백신 소프트웨어 설치, 갱신, 점검 조치<br></br>
4 개인정보 시스템에 대한 접근 권한 설정, 관리 및 침입차단 시스템 등을 이용한 접근 통제장치<br></br>
5 접속기록의 위조·변조 방지를 위한 개인정보처리시스템 접속 일시, 처리 내역 등의 저장 및 이의 확인, 감독, 접속기록의 별도 저장 장치 백업<br></br>
6 개인정보 취급자에 대한 지정과 권한의 설정 및 교육, 개인정보의 안전한 관리<br></br>
7 개인정보가 안전하게 저장·전송될 수 있도록 하기 위한 보안서버 구축, 암호화 기술을 이용한 보안조치<br></br>
8 위 각 대책을 이행하기 위하여 필요한 세부 사항 등<br></br>

<br>

## 8. 개인정보 보호 책임자의 성명, 연락처, 부서
<br>

(1) 이용자의 개인정보를 보호하고 개인 정보와 관련된 불만을 처리하기 위하여 회사는 개인정보 보호 책임자를 두고 있습니다. 개인 정보와 관련한 문의사항이 있으시면 아래의 개인정보 보호 책임자에게 연락 주시기 바랍니다.
<br></br>
이 름: [송은우]
<br></br>
소 속: [주식회사 그레이스풀레인]
<br></br>
연락처: [010 3470 4553]
<br></br>

E-mail: [wecode@gracefulrain.co]
<br></br>
(2) 기타 개인정보 침해에 관한 상담이 필요한 경우에는 한국정보보호진흥원, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.

- 개인정보 침해신고센터 ([privacy.kisa.or.kr](http://privacy.kisa.or.kr/)) 국번 없이 118
- 대검찰청 사이버수사과 ([www.spo.go.kr](http://www.spo.go.kr/)) 국번 없이 1301
- 경찰청 사이버안전국 ([police.go.kr](http://www.police.go.kr/www/security/cyber.jsp)) 국번 없이 182

<br>

## 9. 고지의 의무
<br>

현재 개인정보 처리 방침에서 내용의 추가, 삭제 및 수정이 있을 시에는 변경사항의 시행일의 7일 전부터 회사 사이트의 공지사항을 통하여 고지할 것입니다. 다만, 이용자의 권리 또는 의무에 중요한 내용의 변경은 최소 30일 전에 고지하겠습니다.

<br><br>

## 10. 링크 사이트에 대한 책임
<br>

회사는 이용자에게 다른 외부 사이트로 연결되는 링크를 제공할 수 있습니다. 이 경우 회사는 외부 사이트에 대한 통제권이 없으므로 이용자가 외부 사이트로부터 제공받은 서비스나 자료의 유용성, 진실성, 적법성에 대해 책임 및 보증할 수 없으며, 링크된 외부 사이트의 개인정보처리 방침은 회사와 무관하므로 해당 외부 사이트의 정책을 확인하시기 바랍니다.
`
