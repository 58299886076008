import React, { useEffect, useState   } from 'react'
import { 
  useNavigate, 
  useSearchParams,
  useParams,
} from 'react-router-dom'
import { Button } from '@wecode-codingbootcamp/frontend-commons';

import { PATH, AlertMessage    } from 'constant';
import { defaultContainerWidth } from 'styles/customTailwindCSS';
import { useModal, ModalTypes  } from 'contexts/ModalContext';
import { useGetApprovalPayment } from './hooks';
import { PaymentStatus         } from 'pages/types';
import Completed                 from 'assets/icon/svg/xl/completed.svg'

export const PaymentSuccessPage = () => {
  const modal                         = useModal();
  const navigate                      = useNavigate()
  const [searchParams]                = useSearchParams()
  const [isPurchased, setIsPurchased] = useState(false)
  const getApprovalPayment            = useGetApprovalPayment()
  const { paymentMethod }             = useParams()

  const productInfo = localStorage.getItem('productInfo') 
    ? JSON.parse(localStorage.getItem('productInfo')!) 
    : undefined;

  const goBackToPrevPage = () => {
    const chapterUrl = productInfo 
      ? PATH.COURSE.toChapter(productInfo.courseId, productInfo.sequence) 
      : null;

    const prevPath = chapterUrl ?? localStorage.getItem('redirectUrl');
    prevPath ? navigate(prevPath) : navigate(PATH.PAYMENT.wecoin);

    localStorage.removeItem('productInfo');
    localStorage.removeItem('redirectUrl');
  }

  const processPayment = () => {
    const requiredKeys = ['orderName', 'paymentKey', 'orderId', 'amount'];
    const missingKeys  = requiredKeys.filter(key => !searchParams.has(key));

    if (missingKeys.length > 0) {
      modal?.openModal(ModalTypes.ALERT, {
        type   : 'error',
        dialog : AlertMessage.common.error.unknown
      }).then(() => {
        goBackToPrevPage();
      });

      return;
    }

    const payment = {
      paymentKey    : searchParams.get('paymentKey')!,
      orderId       : searchParams.get('orderId')!,
      orderName     : decodeURIComponent(searchParams.get('orderName')!),
      amount        : Number(searchParams.get('amount'))!,
      paymentMethod : paymentMethod?.toUpperCase(),
      product       : productInfo ? productInfo : null
    };

    getApprovalPayment({
      variables : payment,
      onError(error) {
        modal?.openModal(ModalTypes.ALERT, {
          type   : 'error',
          dialog : AlertMessage.common.error.unknown
        }).then(() => {
          goBackToPrevPage();
        });
      },
      onCompleted: (data) => {
        if (data.confirmPayment.status === PaymentStatus.DONE) {
          setIsPurchased(true);
        } else {
          console.error(data);
          modal?.openModal(ModalTypes.ALERT, {
            type   : 'error',
            dialog : AlertMessage.common.error.unknown
          }).then(() => {
            goBackToPrevPage();
          });
        }
      },
    });
  }

  const handlePaymentApproval = () => {
    if(isPurchased) {
      const timer = setTimeout(() => {
        goBackToPrevPage()
      }, 3000)

      return () => clearTimeout(timer)
    }
  }

  useEffect(() => { processPayment()        } , [])
  useEffect(() => { handlePaymentApproval() } , [isPurchased])

  if (!isPurchased) return <></>

  return (
    <div className={`${defaultContainerWidth} mx-auto mt-8 mb-16 px-6 sm:mb-25`}>
      <h1 className="text-2xl font-bold mb-1 pb-4 border-b border-gray-300">결제가 완료되었습니다.</h1>
      <div className="flex flex-col pb-2 gap-4">
        <img alt="결제성공" src={Completed} className="w-full md:w-7/12 mx-auto" />
        <p className="text-center text-lg md:text-xl font-semibold">Wecoin 충전이 완료되었습니다!</p>
        <p className="text-center md:text-xl"> 잠시 후 이전 페이지로 이동합니다. </p>
      </div>
      <div className="flex w-full mt-4 pt-8 border-t border-gray-400 text-center">
        <Button variant="primary" size="x6large" onClick={goBackToPrevPage} className="mx-auto">
          이전 페이지로 이동
        </Button>
      </div>
    </div>
  );
};

