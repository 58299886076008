import React           from 'react';
import { useNavigate } from 'react-router-dom';
import { Button      } from '@wecode-codingbootcamp/frontend-commons';

import Icon     from 'components/core/Icon/Icon';
import { PATH } from 'constant';

export const CourseCompletedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="
      flex flex-col items-center gap-2
      mx-auto my-auto
      w-[300px]
    ">
      <div className="flex flex-col items-center w-full mb-6">
        <Icon name="completed" />
        <p className="text-2xl font-bold text-black mb-4">완료했습니다!</p>
        <div className="text-md text-gray-600 w-full text-center whitespace-pre-wrap">
          수업을 완료하셨어요! 축하합니다!
          <br />이제 다음 수업에 도전해보세요!
        </div>
      </div>
      <div className="flex w-full px-4 text-center">
        <Button size="full" onClick={() => navigate(PATH.HOME.base)}>
          홈으로
        </Button>
      </div>
    </div>
  );
};
