import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button      } from '@wecode-codingbootcamp/frontend-commons';

import ProgressBar from 'components/core/ProgressBar';
import {  PATH   } from 'constant';

type MyPageCardVariant = 'wishList' | 'myCourse';
type Data              = {
  id         : number,
  title      : string,
  thumbnail? : string,
  progress?  : number,
}

interface Props {
  data           : Data
  variant        : MyPageCardVariant
  hasWishBtn?    : boolean
  handleWishBtn? : () => void
};

const MyPageCard = ({
  data,
  variant,
  handleWishBtn,
  hasWishBtn = true,
}: Props) => {
  const { 
    id,
    title,
    thumbnail,
  } = data;

  const navigate = useNavigate();

  /***********************************************************************/
  /*****                      Components                            *****/
  /***********************************************************************/
  const Image = () => (
    <div className="relative w-[135px] lg:w-full h-[80px] lg:h-[164px] mb-3">
      <img className="w-full h-full rounded-lg object-cover" alt="card" src={thumbnail} />
    </div>
  );

  const Title = () => {
    const multilineTruncate: React.CSSProperties = {
      display         : '-webkit-box',
      WebkitLineClamp : 2, // Number of lines to show
      WebkitBoxOrient : 'vertical',
      overflow        : 'hidden',
    };

    return (
      <div className="h-[56px] pt-1 md:pt-0 md:pr-8 lg:pr-0">
        <h1 
          style     = {multilineTruncate}
          className ="
          w-full break-keep
          text-left lg:text-center md:text-lg md:text-md font-semibold text-themeBlack
        ">{title}</h1>
      </div>
    );
  };

  const CourseProgress = () => (
    <div className="flex flex-col lg:items-center flex-wrap mt-auto">
      <div className="flex justify-center items-center w-[90%] md:w-[70%] p-0 md:w-[80%] my-4 gap-4">
        <ProgressBar
          className = "w-full"
          variant   = "thin"
          progress  = {data.progress ?? 0}
        />
      </div>
    </div>
  );

  const WishListButton = () => (
    <div className="flex flex-wrap justify-between items-center lg:mt-2 pr-1">
      <div className="w-[49%]">
        <Button
          className = "h-[26px] text-sm"
          variant   = "lineGrey"
          size      = "full"
          onClick   = {() => handleWishBtn && handleWishBtn()}
        >
          찜취소
        </Button>
      </div>
      <div className="w-[49%]">
        <Button
          className = "h-[26px] text-xs"
          variant   = "primary"
          size      = "full"
          onClick   = {() => navigate(PATH.COURSE.toCourse(id)) }
        >
          시작하기
        </Button>
      </div>
    </div>
  );
  

  return (
    <div
      className ="
        flex lg:flex-col 
        items-stretch justfy-start 
        gap-4 lg:gap-0 
        cursor-pointer 
        h-[100px] lg:h-full
      "
    >
      <div onClick = {() => navigate(PATH.COURSE.toCourse(id)) }>
        <Image />
        <div className="flex flex-col w-[70%] lg:w-full">
          <Title />
        </div>
      </div>
      { variant === 'myCourse' ? <CourseProgress /> : <WishListButton />}
    </div>
  )
}

export default memo(MyPageCard)
