import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/autoplay'

import Slide1_lg from 'assets/page/classPage/Course4-main-slider-01.png';
import Slide1_sm from 'assets/page/classPage/Course4-main-slider-01-md.png';
import Slide1_md from 'assets/page/classPage/Course4-main-slider-01-md.png';
import Slide2_lg from 'assets/page/classPage/Course7-main-slider-02.png';
import Slide2_sm from 'assets/page/classPage/Course7-main-slider-02-md.png';
import Slide2_md from 'assets/page/classPage/Course7-main-slider-02-md.png';
import Slide3_lg from 'assets/page/classPage/Course11-main-slider-03.png';
import Slide3_sm from 'assets/page/classPage/Course11-main-slider-03-md.png';
import Slide3_md from 'assets/page/classPage/Course11-main-slider-03-md.png';
import Slide4_lg from 'assets/page/classPage/Course9-main-slider-04.png';
import Slide4_sm from 'assets/page/classPage/Course9-main-slider-04-md.png';
import Slide4_md from 'assets/page/classPage/Course9-main-slider-04-md.png';
import Slide5_lg from 'assets/page/classPage/Course10-main-slider-05.png';
import Slide5_sm from 'assets/page/classPage/Course10-main-slider-05-md.png';
import Slide5_md from 'assets/page/classPage/Course10-main-slider-05-md.png';
import Slide6_lg from 'assets/page/classPage/Course12-main-slider-06.png';
import Slide6_sm from 'assets/page/classPage/Course12-main-slider-06-md.png';
import Slide6_md from 'assets/page/classPage/Course12-main-slider-06-md.png';

import { PATH } from 'constant';
import css from './ClassPage.module.scss'
import { defaultContainerWidth } from 'styles/customTailwindCSS';

export const SLIDE_DATA = [
  {
    id: 4,
    lg: Slide1_lg,
    md: Slide1_md,
    sm: Slide1_sm,
    name: '지금 딱 원하는 정보만 슬라이드 스크랩북',
  },
  {
    id: 7,
    lg: Slide2_lg,
    md: Slide2_md,
    sm: Slide2_sm,
    name: '웹 사이트 만들기 기초',
  },
  {
    id: 11,
    lg: Slide3_lg,
    md: Slide3_md,
    sm: Slide3_sm,
    name: '브라우저 이벤트와 함수 핸들러',
  },
  {
    id: 9,
    lg: Slide4_lg,
    md: Slide4_md,
    sm: Slide4_sm,
    name: 'JS 배열과 반복문',
  },
  {
    id: 10,
    lg: Slide5_lg,
    md: Slide5_md,
    sm: Slide5_sm,
    name: 'JS 객체와 함수',
  },
  {
    id: 12,
    lg: Slide6_lg,
    md: Slide6_md,
    sm: Slide6_sm,
    name: '브라우저 저장소'
  }
];

const ClassPageHeader = () => {
  const navigate = useNavigate();

  return (
    <div style={{ maxWidth: '100vw' }} >
      <header />
      <section>
        <div className="border-b border-grey90 pb-2">
          <Swiper
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            autoplay={{
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop
          >
            {SLIDE_DATA.map(({ id, lg, md, sm }, index) => (
              <SwiperSlide key={index}>
                <div className={`
                  ${defaultContainerWidth} mx-auto
                  flex items-center justify-center 
                  h-full
                `}>
                  <picture
                    className="
                      flex items-center justify-center
                      w-[min(100%-48px,1152px)]
                      pt-8 pb-6 md:py-10 lg:py-[86px]
                    "
                    onClick={() => navigate(PATH.COURSE.toDescription(id))}
                  >
                    <source media="(max-width: 525px)" srcSet={sm} />
                    <source media="(max-width: 1023px)" srcSet={md} />
                    <img
                      className="
                        w-full md:w-[688px] lg:w-full 
                        object-contain object-center 
                        cursor-pointer
                      "
                      src={lg}
                      alt={`slide${index}`}
                    />
                  </picture>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default ClassPageHeader;
