import React           from 'react';
import { useNavigate } from 'react-router-dom'
import { Button      } from '@wecode-codingbootcamp/frontend-commons';

import WishListHeartIcon from 'components/core/WishListHeartIcon';
import ShareIconButton from 'components/core/ShareIconButton';
import { UserContextType } from 'contexts/UserContext'
import { Course } from 'pages/types'
import { PATH } from 'constant';
import data from './data';

interface SideBarProps {
  user          : UserContextType | null;
  course        : Course;
  wishListed    : boolean;
  setWishListed : () => void;
}

const SideBar = ({ user, course, wishListed, setWishListed }: SideBarProps) => {
  const navigate = useNavigate();

  const StartButton = () => {
    const goToLatestChapter = () => {
      // If user has access to the course, navigate to the latest chapter user has accessed.
      // Otherwise, navigate to the first chapter which is usually free
      if (course.userAccessInfo) {
        navigate(PATH.COURSE.toLatestChapter(course.id));
      } else {
        navigate(PATH.COURSE.toChapter(course.id, 1));
      }
    }

    return (
      <Button className="mb-4" size="full" onClick={goToLatestChapter}>바로 시작하기</Button>
    );
  };

  const CourseInfo = () => {
    const courseData = data[course.id] || [];

    return (
      <div className="bg-gray-100 p-5 rounded">
        <ul className="text-sm text-gray-600 list-disc list-inside">
          {
            courseData.map((item: string, index: number) => (
              <li key={index} className="mb-2">{item}</li>
            ))
          }
        </ul>
      </div>
    );
  };

  const Desktop = ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="hidden lg:block pb-4" >
        {children}
      </div>
    );
  };

  const Mobile = ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="
        lg:hidden fixed bottom-0 left-0 
        flex justify-evenly
        w-full p-4 gap-4
        bg-white
        border-t border-gray-300
      ">
        {children}
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <aside className="
          sticky top-24 
          ml-20 w-80 
          overflow-y-autobg-white 
          border border-gray-300 shadow-md rounded-md
        ">
          <div className="flex flex-col items-center p-4">
            <div className="flex items-center justify-between w-full">
              <div className="w-full text-4xl text-center font-bold mb-4">{course.price.toLocaleString()}원</div>
            </div>
            <StartButton />
            <div className="flex justify-center gap-[50px] w-full my-2 mx-4 px-4">
              <WishListHeartIcon isWishListed={wishListed} onClick={() => setWishListed()} />
              <ShareIconButton course={course} />
            </div>
          </div>
          <CourseInfo />
        </aside>
      </Desktop>
      <Mobile>
        <StartButton />
      </Mobile>
    </>
  );
};

export default SideBar;

