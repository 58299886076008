import React, { useState }                   from 'react';
import { useNavigate, useLocation          } from 'react-router-dom';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { Button                            } from '@wecode-codingbootcamp/frontend-commons';

import Icon     from 'components/core/Icon/Icon';
import { PATH } from 'constant';

type Props = {
  errorMessage?: string;
};

export const ErrorPage = ({errorMessage}: Props) => {
  const navigate                      = useNavigate();
  const queryParams                   = new URLSearchParams(useLocation().search)
  const error                         = queryParams.get('error') ?? errorMessage;
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="flex flex-col items-center mx-auto py-10 my-auto min-w-80 max-w-screen-xl md:px-8 text-center">
      <div className="md:hidden lg:block">
        <Icon name="warning" size={100} />
      </div>
      <div className="hidden md:block lg:hidden">
        <Icon name="warning" size={80} />
      </div>
      <p className="text-base text-gray-900 mt-6">오류가 발생한거 같아요.</p>
      <p className="text-base mb-10 md:mb-0 mt-2">
        해당 문제가 계속된다면,{' '}
        <a
          href      = "https://wecode.channel.io"
          className = "text-primary underline"
          target    = "_blank"
          rel       = "noreferrer"
        >
          고객센터
        </a>
        로 문의해주세요.
      </p>
      {error && (
        <div className="w-full md:mt-6">
          <div className="text-gray-700 text-sm font-semibold cursor-pointer flex items-center justify-center" onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-700 mr-2" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-700 mr-2" />
            )}
            상세 정보 {showDetails ? '숨기기' : '보기'}
          </div>
            {showDetails && (
              <div className="bg-gray-100 p-4 mt-1 rounded-md w-full mt-2 mb-3 md:w-80 mx-auto">
                {error}
              </div>
            )}
        </div>
      )}
      <div className="flex flex-col items-center w-80 md:w-64 lg:w-80 gap-2 mt-2">
        <Button size="full" onClick={() => navigate(PATH.HOME.base)}>
          홈으로
        </Button>
      </div>
    </div>
  );
};

