import React      from 'react';
import { Button } from '@wecode-codingbootcamp/frontend-commons';

import css           from './BaseModal.module.scss';
import { AlertType } from 'pages/types';

const buttonVariantsMap = {
  positive : 'primary',
  warn     : 'lineBase',
  info     : 'primary',
  error    : 'lineBase',
} as const;

interface ModalFooterProps {
  type            : AlertType;
  hasCancelButton : boolean;
  onClick?        : {
    negative?: () => void;
    positive : () => void;
  };
}

const ModalFooter = ({ type, hasCancelButton, onClick }: ModalFooterProps) => {
  if (hasCancelButton) {
    return (
      <footer className={css.footer}>
        <Button variant="lineBase" size="large" onClick={onClick?.negative}>
          취소
        </Button>
        <Button size="large" onClick={onClick?.positive}>
          확인
        </Button>
      </footer>
    );
  }

  return (
    <footer className={css.footer}>
      <Button
        size    = "large"
        variant = {buttonVariantsMap[type]}
        onClick = {onClick?.negative}
      >
        확인
      </Button>
    </footer>
  );
};

export default ModalFooter;
