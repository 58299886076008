import React               from 'react';
import { MarkdownContent } from '@wecode-codingbootcamp/frontend-commons';

import css from './Term.module.scss';

const Refund = () => {
  return (
    <div className={css.refund}>
      <div className={css.left}>환불 안내</div>
      <div className={css.right}>
        <MarkdownContent content={MD.trim()} />
      </div>
    </div>
  );
};

const MD = `
# 구매 및 환불 약관

<br>

## 제1조 (정의)
1 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

- (1) "전용상품권"은 위코드 서비스에서 콘텐츠를 구매 또는 대여하기 위해 사용할 수 있는 위코드가 발행한 전자적 증표 또는 그 증표에 관한 정보로서, 전자적 지급수단을 말하며 명칭은 위코드의 결정에 따라 변경될 수 있습니다. 위코드는 콘텐츠의 종류별로 별도의 전용상품권을 발행할 수 있으며 개별 전용상품권에 대한 정책에 따라 이를 운영합니다.

- (2) "작품별이용권" 이라 함은 위코드 서비스에서 특정한 종류의 콘텐츠의 구매 또는 대여를 위해 사용할 수 있는 위코드가 발행한 전자적 증표 또는 그 증표에 관한 정보로서, 대여모델에 사용되는 대여권과 구매모델에 사용되는 소장권으로 구분됩니다. 작품별이용권의 종류는 위코드의 정책에 따라 변경될 수 있습니다.

- (3) "전용상품권 등"이라 함은 전용상품권과 작품별이용권을 말합니다.
<br>

2 이 약관에 정의되지 않은 용어는 위코드 이용약관의 정의에 따릅니다.
<br><br><br>

## 제2조 (전용상품권의 구매 등)

1 위코드는 이용자의 전용상품권 구매 전에 전용상품권에 대한 거래 조건을 정확하게 이해하고 실수나 착오 없이 거래할 수 있도록 다음 각 호의 사항들을 적절한 방법으로 이 약관 및 서비스 내에서 고지합니다.

- (1) 판매자의 상호, 대표자 성명, 주소, 전화번호 등

- (2) 전용상품권의 확인 및 선택, 구매 방법의 선택 및 결제정보의 입력, 구매 신청에 관한 확인, 종류 및 가격, 이용방법, 이용시기, 이용조건 등 구체적 내용

- (3) 청약철회 및 계약해제의 기한・행사방법 및 효과, 환불 등에 관한 사항

- (4) 소비자의 피해보상, 불만 처리 및 분쟁 처리에 관한 사항

<br>
2 위코드는 이용자의 전용상품권 구매 신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보하거나, 추후 승낙을 취소할 수 있습니다.

- (1) 실명이 아니거나 타인의 명의를 이용한 경우

- (2) 허위의 정보를 기재하거나, 필수 사항을 기재하지 않은 경우

- (3) 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우

- (4) 동일 아이피의 대량 결제, 유사 아이디에서의 연속적인 결제, 위험 아이피에서의 결제 등 도용에 의한 전용상품권의 구매로 판단되는 경우

- (5) 만 19세 미만의 미성년자가 법정대리인의 동의 없이 신청한 경우

- (6) 결제에 대한 지급이 실제로 이루어지지 않았거나 않을 가능성이 있는 경우

- (7) 기타 이용자의 전용상품권 구매 신청이 위코드와 제3자의 이익을 훼손할 위험이 있다고 판단되는 경우

<br>

3 전용상품권의 구매 계약은 위코드가 구매 완료를 신청절차 상에서 표시 또는 이용자에게 고지한 시점에 성립되며, 이때 위코드는 제1항 각 호의 내용을 포함하여 계약체결의 내용을 즉시 이용자에게 교부합니다.
<br><br>

4 이용자는 이 약관 동의 및 전용상품권 구매에 따른 결제 후 서비스에서 전용상품권을 이용할 수 있습니다. 단, 구매를 전제하지 않는 무상 전용상품권 및 무상 작품별이용권의 경우에는 이 약관에 동의 후 이용할 수 있습니다.
<br><br>

5 위코드는 이용자에게 사전에 공지하고 전용상품권 등의 종류와 가격, 지급수량 등 그 구체적인 내용을 변경하거나 특정한 전용상품권 및 작품별이용권을 폐지할 수 있습니다. 단, 그와 같은 변경 및 폐지가 이용자에게 불리한 경우에는 공지 외 네이버 서비스 내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 개별적으로 통지하여야 합니다.


<br><br><br>
## 제3조 (청약철회등)
## 가. 동영상 학습비의 구매계약 철회, 반환 등

<br>

### 1. 청약 철회
<br>

1 이용자는 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령에서 정한 바에 따라 유상으로 구매한 전용상품권에 대하여 계약체결에 대한 내용을 교부 받은 시점 혹은 구매한 전용상품권을 제공받은 시점으로부터 7일 이내에 청약의 철회 및 계약의 해제(이하 "청약철회등")를 할 수 있습니다. 단, 이용자가 구매한 전용상품권을 이미 사용한 경우에는 사용한 부분에 대해서는 청약철회등이 불가능하며, 이러한 불가능 사유에 대해서는 전용상품권 구매 시 위코드가 이용자에게 명확하게 고지합니다.
<br><br>
2 전항에도 불구하고 전용상품권의 내용이 표시・광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 이용자는 그 전용상품권을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다.
<br><br>
3 제1항 또는 제2항에 따라서 이용자가 청약철회등을 한 경우 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 이용자가 구매한 전용상품권은 반환되며, 위코드는 동법에 따라 환급 절차를 진행합니다.
<br><br>
4 그 밖에 본 조에 따른 청약철회등에 대한 사항은 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 규율 및 처리됩니다.
<br><br>
5 이용자의 청약철회권이 제한되는 경우<br></br>

가. 이용자의 책임 있는 사유로 서비스가 멸실되거나 훼손된 경우<br></br>

나. 이용자의 사용 또는 일부 소비로 서비스의 가치가 현저히 감소한 경우<br></br>

다. 시간의 경과에 의해 재판매가 곤란할 정도로 서비스의 가치가 현저히 감소한 경우<br><br>

라. 복제가 가능한 서비스의 포장을 훼손한 경우

<br>
<br>

### 2. 학습비 반환
<br>

유료 서비스 금액(학습비)의 반환에 관하여 "학원의 설립 운영 및 과외 교습에 관한 법률" 등에서 정하고 있는 기준에 의해 반환합니다.

1. 일부 디지털 콘텐츠의 경우, 이용자의 기타 사유에 의한 수업 연장 기간을 포함하지 않습니다.
2. 이용자가 할인에 의해 학습비를 결제하였을 경우, 학습비 반환 금액의 기준은 할인이 적용되어 실제 결제한 금액에 의하여 정합니다.
3. 위코드는 이용자가 구매한 전용상품권의 미사용분에 대해 환불을 요구할 경우 환불수수료에 해당하는 금액을 공제하고 환불할 수 있습니다. 단, 네이버웹툰의 귀책으로 인한 경우에는 환불수수료를 공제하지 않습니다. 이용자가 전용상품권의 구매 시 함께 지급된 추가 무상전용상품권을 사용한 경우에는 유상 전용상품권의 미사용분에서 해당 분량을 제외하고 환불됩니다.

4.  무상 전용상품권은 전항의 환불 대상에서 제외됩니다.
5. 학습비 반환 사유가 발생한 날부터 5일(영업일 기준) 이내에 반환합니다.
4. 이용자가 유료 서비스 결제 후 7일 이내 해지를 요구하는 경우 2강 이하 열람 시 또는 학습 2단계 이하 열람 시 전액 환불됩니다. 7일 이내라도 3강 이상 열람 또는 학습 3단계 이상 열람 시에는 부분 환불됩니다.
5. 증정품(교재, 사은품)이 포함된 경우 처음 수령했을 때 상태 그대로 증정품을 반환하셔야 하며, 왕복 택배비는 이용자가 부담하여야 합니다.
6. 구독형 학습비의 경우 구매 후 7일 이내 구독 취소(환불 요청) 시, 이용 이력이 없을 경우 환불 가능합니다.
7. 무상 작품별이용권은 전항의 환불 대상에서 제외됩니다.

### 3. 기타

- 회사와 이용자가 체결한 구매계약에 의해서 제공되는 내역이 디지털 콘텐츠(동영상 등)의 학습비와 재화(교재·도서 등)가 포함되었을 경우, 각각 구분하여 학습비 반환 및 환급 기준을 적용합니다.
- 미성년자와 거래에 관한 계약 시 법정대리인이 그 계약에 동의하지 아니하면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있습니다.

<br><br>

## 나. 교재·도서의 반품 및 환불

<br>

다음 각호의 경우에는, 배송된 재화일지라도 교재·도서를 반품 받은 다음 5일(영업일 기준) 이내에 이용자의 요구에 따라 즉시 환급, 반품 및 교환 조치를 합니다. 단, 이용자의 그 반품 및 교환 조치의 요구 기간은 배송된 날로부터 7일 이내에만 가능합니다.

   1. 배송된 재화가 주문 내용과 상이하거나 회사가 제공하는 "서비스"에서 게시된 정보와 상이할 경우
   2. 배송된 재화가 파손, 손상되었거나 오염되었을 경우 (단, 이용자의 고의·과실이 없어야 합니다.)
   3. 재화가 광고에 표시된 배송기간보다 늦게 배송된 경우

이용자가 교재·도서를 구매 후 사용하여 상품 가치에 손상을 입혔을 경우, 어떠한 사유에서도 환불 및 반품이 되지 않습니다. 또한 완질의 경우 완성품으로 일부의 교재를 사용한 경우에도 전량 환불 및 반품이 되지 않습니다.

<br><br>

## 제4조 (유효기간 등)

- (1) 유상 전용상품권의 유효기간은 마지막 충전일 또는 사용일부터 5년입니다. 다만, 무상 전용상품권의 경우 제공 시 이용자에게 안내한 이용조건에 따라 유효기간이 정해질 수 있습니다.

- (2) 유상 작품별이용권의 유효기간은 구매일로부터 5년입니다. 다만, 무상 작품별이용권의 경우 제공 시 이용자에게 안내한 이용조건에 따라 유효기간이 정해질 수 있으며, 별도의 안내가 없는 경우 유상 작품별이용권의 유효기간과 같은 것으로 간주합니다

- (3) 유효기간 내 전용상품권이라 할 지라도 제3자에게 양도, 판매, 담보제공 등의 방법으로 처분하여서는 아니됩니다.

`;
export default Refund;
