import React, { useEffect, useState } from 'react'
import { useNavigate, useParams     } from 'react-router-dom'
import { MarkdownContent            } from '@wecode-codingbootcamp/frontend-commons'

import CurriculumTable           from 'components/core/CurriculumTable';
import LogoCoverBelt             from 'components/core/LogoCoverBelt'
import useCreateHeartMutation    from './hooks/useCreateHeartMutation';
import useDeleteHeartMutation    from './hooks/useDeleteHeartMutation';
import SideBar                   from './SideBar';
import CourseBanner              from './CourseBanner';
import { PATH, AlertMessage    } from 'constant';
import { useUser               } from 'contexts/UserContext';
import { defaultContainerWidth } from 'styles/customTailwindCSS';
import { useModal, ModalTypes  } from 'contexts/ModalContext';
import { pushViewItemEvent     } from 'utils/gtm';
import { useCourseQuery        } from './hooks';
import { ProductType }           from 'pages/types';

export const CoursePage = () => {
  const user                        = useUser();
  const modal                       = useModal();
  const navigate                    = useNavigate();
  const { courseId = '' }           = useParams();
  const id                          = Number(courseId);
  const isWishListed                = user?.wishList?.some((item) => item.courseId === id);
  const [wishListed, setWishListed] = useState(!!isWishListed);

  /***********************************************************************/
  /*****                      Fetches                                *****/
  /***********************************************************************/
  const {course, error} = useCourseQuery(Number(courseId));
  const promptLogin     = (error: any) => { 
    if (error?.graphQLErrors[0]?.message === 'NOT_AUTHORIZED') {
      modal?.openModal(ModalTypes.LOGIN);
    }
  }

  const createHeart = useCreateHeartMutation({courseId : id, onError: promptLogin});
  const deleteHeart = useDeleteHeartMutation({courseId : id, onError: promptLogin});
  const changeHeart = () => {
    if (wishListed) {
      setWishListed(false);
      deleteHeart({ variables: { courseIds: [id] } })
    } else {
      setWishListed(true);
      createHeart({ variables: { courseIds: [id] } })
    }
  }

  /***********************************************************************/
  /*****                      UseEffects                             *****/
  /***********************************************************************/
  useEffect(() => {
    // Sends the view item event to GTM
    // on the first render of the course page
    if (course) {
      pushViewItemEvent({
        productId   : course.id,
        productType : ProductType.COURSE,
        name        : course.title,
        price       : course.price,
      });
    }
  }, [course]); 

  useEffect(() => {
    setWishListed(!!isWishListed);
  }, [isWishListed, user?.wishList]);

  useEffect(() => {
    /***********************************************************************
    * Handles error when fetching chapter
    * If the error is NOT_AUTHORIZED, it redirects to the payment page
    * If the user is not authenticated, it redirects to the login page
    * Otherwise, it opens an alert modal
    ***********************************************************************/
    if (error) { 
      modal?.openModal(ModalTypes.ALERT, {
        type             : 'error',
        dialog           : AlertMessage.common.error.unknown,
        hasHelpEmailInfo : true,
      })

      if(error.message === 'NOT FOUND') {
        return navigate(PATH.NOTFOUND.notFound);
      }

      if(error?.message === 'NOT AUTHORIZED') {
        if (user?.isAuthenticated) {
          const paymentUrl = PATH.COURSE.toPayment(Number(courseId));
          return navigate(`${paymentUrl}?redirectUrl=${location.pathname}`);
        } else {
          return navigate(PATH.LOGIN.toLogin(location.pathname));
        }
      }

      return navigate(PATH.ERROR.toError(error.message));
    }
  }, [error]);

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  if (! course ) { return null; }

  return (
    <>
      <CourseBanner 
        course        = {course}
        wishListed    = {wishListed}
        setWishListed = {changeHeart}
      />
      <div className={`${defaultContainerWidth} mx-auto my-8 flex`}>
        <div className="flex flex-col w-full">
          <MarkdownContent content  = {course.description ?? ''}          />
          <CurriculumTable 
            courseId        = {course.id}
            chapters        = {course.chapterInfo.chapters}
            chapterProgress = {course?.userAccessInfo?.chapterProgress ?? []}
            authorized      = {!!course?.userAccessInfo}
          />
        </div>
        <SideBar         
          user          = {user}
          course        = {course}
          wishListed    = {wishListed}
          setWishListed = {changeHeart}
        />
      </div>
      <LogoCoverBelt text="" className="hidden lg:block" />
    </>
  );
}
